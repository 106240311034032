
import mdcAutoInit from '@material/auto-init/index';
window.mdcAutoInit = mdcAutoInit;
mdcAutoInit.register('MDCRipple', MDCRipple);
mdcAutoInit.register('MDCTextField', MDCTextField);

import { MDCRipple } from '@material/ripple/index';
const rippleElements = [].map.call(document.querySelectorAll('.mdc-button, .mdc-ripple-surface'), function (el) { // eslint-disable-line
    return new MDCRipple(el);
});

import { MDCTextField } from '@material/textfield/index';
const textFieldElements = [].map.call(document.querySelectorAll('.mdc-text-field'), function (el) { // eslint-disable-line
    return new MDCTextField(el);
});

import { MDCSelect } from '@material/select/index';
const selectElements = [].map.call(document.querySelectorAll('.mdc-select'), function (el) { // eslint-disable-line
    return new MDCSelect(el);
});

/*
import { MDCTopAppBar } from '@material/top-app-bar/index';
const topAppBarElement = document.querySelector('.mdc-top-app-bar');
if(topAppBarElement) {
    const topAppBar = new MDCTopAppBar(topAppBarElement); // eslint-disable-line
}
*/

import { MDCDialog } from '@material/dialog/index';
const dialogElements = [].map.call(document.querySelectorAll('.mdc-dialog'), function (el) {
    return new MDCDialog(el);
});
const mailDialog = function() {
    const dialogLink = document.querySelectorAll('.js-mypage__mail-detail-title-link'),
          dialogLinkLen = dialogLink.length,
          dialogTitle = document.getElementById('mail-dialog-title'),
          dialogBody = document.getElementById('mail-dialog-content');
    for (var i = 0; i < dialogLinkLen; i++) {
        dialogLink[i].onclick = function() {
            const mailTitle = this.innerText;
            const mailBody = this.closest('.js-mypage__mail-detail').children[2].innerHTML;
            dialogTitle.textContent = mailTitle;
            dialogBody.innerHTML = mailBody;
            dialogElements[0].open();
        }

    }
}
window.addEventListener('DOMContentLoaded', mailDialog, false);

import {MDCMenu} from '@material/menu/index';
const menuElements = [].map.call(document.querySelectorAll('.mdc-menu'), function (el) {
    return new MDCMenu(el);
});
window.menuElements = menuElements;
const naviMenu = function() {
    const menuLink = document.querySelectorAll('#js-header__navi-icon'),
          menuLinkLen = menuLink.length
    for (var i = 0; i < menuLinkLen; i++) {
        menuLink[i].onclick = function() {
            menuElements[0].open = true;
        }

    }
}
window.addEventListener('DOMContentLoaded', naviMenu, false);
